import React from "react";
import { useLocation } from "@reach/router";
import { Link, graphql } from "gatsby";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const authors = require("../../authors.json");

const createSlug = (title) => {
  const slug = title.replace(/\W+/g, "-").toLowerCase();
  return slug;
};

export const query = graphql`
  query {
    allContentfulArticle {
      edges {
        node {
          id
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          slug
          thumbnailImageUrl
        }
      }
    }
    allCountries {
      edges {
        node {
          name
          slug
        }
      }
    }
    allRegions {
      edges {
        node {
          name
          slug
          country {
            name
          }
        }
      }
    }
    allCities {
      edges {
        node {
          name
          slug
          country {
            name
          }
          regionCities {
            region {
              name
              slug
            }
          }
        }
      }
    }
  }
`;

const SitemapPage = ({ data }) => {
  const articles = data.allContentfulArticle.edges;
  const countries = data.allCountries.edges;
  const regions = data.allRegions.edges;
  const cities = data.allCities.edges;

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>SITEMAP</SectionHeading>
          <div>Articles</div>
          <div style={{ maxWidth: 900 }}>
            {articles.map((article) => (
              <Link to={article.node.slug}>
                <div>{article.node.title}</div>
              </Link>
            ))}
          </div>
          <div>Countries</div>
          <div style={{ maxWidth: 900 }}>
            {countries.map((country) => (
              <Link to={`/${createSlug(country.node.name)}`}>
                <div>{country.node.name}</div>
              </Link>
            ))}
          </div>
          <div>Regions</div>
          <div style={{ maxWidth: 900 }}>
            {regions.map((region) => {
              const regionPath = `/${createSlug(
                region.node.country.name
              )}/${region.node.slug.replace(
                `-${createSlug(region.node.country.name)}`,
                ""
              )}`;

              return (
                <Link to={regionPath}>
                  <div>{region.node.name}</div>
                </Link>
              );
            })}
          </div>
          <div>Cities</div>
          <div style={{ maxWidth: 900 }}>
            {cities.map((city) => {
              let cityPath = `/${createSlug(
                city.node.country.name
              )}/${city.node.slug.replace(
                `-${createSlug(city.node.country.name)}`,
                ""
              )}`;

              if (city.node.regionCities && city.node.regionCities.length > 0) {
                cityPath = `/${createSlug(
                  city.node.country.name
                )}/${city.node.regionCities[0].region.slug.replace(
                  `-${createSlug(city.node.country.name)}`,
                  ""
                )}/${city.node.slug.replace(
                  `-${createSlug(city.node.country.name)}`,
                  ""
                )}`;
              }

              return (
                <Link to={cityPath}>
                  <div>{city.node.name}</div>
                </Link>
              );
            })}
          </div>
          <div>Authors</div>
          <div style={{ maxWidth: 900 }}>
            {authors.map((author) => (
              <Link to={`/author/${author.name.toLowerCase()}`}>
                <div>{author.fullName}</div>
              </Link>
            ))}
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default SitemapPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO index follow title={"Travel Lingual"} pathname={location.pathname} />
  );
};
